import CountDown from "../../countdown/Index.vue";

export default {
  name: "SignUp",
  props: {
    titleText: {
      type: String
    }
  },
  data() {
    return {
      componentLoader: false,
      otpSent: false,

      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",

      valid: true,
      lazy: false,

      formRules: {
        required: true,
        validateOtp: true,
        password: true,
        validPassword: true,
        validEmail: true
      },
      send_otp_by: "",
      otpTimeOutTime: 0,
      validOtp: "",
      otpStatusMessage: "",
      forgotPasswordEmail:"",
      countryCode:91,
      phoneNumberVal:null
    };
  },

  components: {
    CountDown
 },

  watch: {
    '$store.state.language'() {
      if (!this.otpSent) {
        this.$refs.form.validate();
      }
      else if (this.otpSent) {
        this.$refs.verifyOtpForm.validate();
      }

    },
  },

  methods: {


    signUp(type) {
      this.otpStatusMessage = null;
      var source;
      if (this.titleText == "forgot") {
        source = "forgot_password"
      }
      else {
        source = "signup"
      }
      if (type == "resend") {
        this.componentLoader = true;
        this.getOtpFunction(source)
      }
      else {


        this.formRules = {
          required: value => !!value || this.$t('common.required'),
          validateOtp: value => (value && value.length == 6) || this.$t('common.sixDigiNumber'),
          password: value => !!value && value.length >= 6 || this.$t('common.atLeastSixCharacters'),
          validPassword: value => !!value && value.length >= 6 && value == this.password || this.$t('common.shouldBeSame'),
          validEmail: value => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) || this.$t("common.email_valid"),
          tenNumbers: value => (value && ( value.length > 7 &&  value.length < 12)) || this.$t('account.valid_ten_dig_phone'),
        }

        setTimeout(() => {
          if (this.$refs.signUpForm.validate()) {

            this.getOtpFunction(source)
            this.componentLoader = true;

          }

        }, 100)
      }





    },


    getRestPasswordOtp() {

      this.formRules.required = value => !!value || this.$t('common.required');
      this.formRules.validEmail =  value => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) || this.$t("common.email_valid")

      setTimeout(() => {
        if (this.$refs.getRestPasswordOtpForm.validate()) {
          this.componentLoader = true;
          this.$store.dispatch("connectToApi", {
            url: "user/change-password",
            params: {
              email: this.email
            },
            rule: "post",
            token: false,
            commonLoader: false,
          })
            .then((response) => {
              console.log(response);
              this.otpTimeOutTime = null;
              this.componentLoader = false;
              this.otpStatusMessage = response.data.message;
              this.otpSent = true;
              this.formRules = {
                required: true,
                validateOtp: true,
                password: true,
                validPassword: true,
                validEmail: true
              },

                setTimeout(() => {
                  this.$refs.otp.focus();

                  this.otpTimeOutTime = Number(response.data.data.otp_expire_duration_in_seconds);
                  
                  var resetpasswordDetails = {
                    "optTimeout": this.otpTimeOutTime,
                    "message":this.otpStatusMessage,
                    "email":this.email
                  }
                  

                  this.$emit('showCreatePassword', resetpasswordDetails)

                });

            }).catch((er) => {
              this.componentLoader = false;
              console.log(er);
            });
        }
      })
    },

    getOtpFunction() {

      this.$store.dispatch("connectToApi", {
        url: "user/register",
        params: {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          country_code:this.countryCode,
          mobile_number:this.phoneNumberVal,
          password: this.password,
          password_confirmation: this.confirmPassword
        },
        rule: "post",
        token: false,
        commonLoader: false,
      })
        .then((response) => {
          this.otpTimeOutTime = null;
          this.componentLoader = false;
          this.otpStatusMessage = response.data.message;
          this.otpSent = true;
          this.formRules = {
            required: true,
            validateOtp: true,
            password: true,
            validPassword: true,
            validEmail: true
          },

            setTimeout(() => {
              this.$refs.otp.focus();
              this.otpTimeOutTime = Number(response.data.data.otp_expire_duration_in_seconds);

            });

        }).catch((er) => {
          this.componentLoader = false;
          console.log(er);
        });
    },


    verifyOtp() {
      this.formRules.required = value => !!value || this.$t('common.required');
      this.formRules.required = value => (value && value.length == 6) || this.$t('common.sixDigiNumber');


      setTimeout(() => {
        if (this.$refs.verifyOtpForm.validate()) {

            this.componentLoader = true;
            this.$store.dispatch("connectToApi", {
              url: "user/verify-email",
              params: {
                email: this.email,
                otp: this.validOtp
              },
              rule: "patch",
              token: false,
              commonLoader: false,
            })
              .then(() => {
                this.componentLoader = false;
                this.otpSent = false;
                this.showLoginComp();
              }).catch((er) => {
                this.componentLoader = false;
                console.log(er);
              });
         
         


        }
      }, 10)
    },

    resendOtp() {
      this.otpStatusMessage = null;
      this.componentLoader = true;
      this.otpTimeOutTime = 0;
      var url;

      if (this.titleText == "forgot") {
        url = "user/change-password"
      }
      else {
        url = "user/resend-otp"
      }

      

      this.$store.dispatch("connectToApi", {
        url: url,
        params: {
          email: this.email,
        },
        rule: "post",
        token: false,
        commonLoader: false,
      })
        .then((response) => {
          this.componentLoader = false;
          this.otpStatusMessage = response.data.message;
          setTimeout(() => {
            this.otpTimeOutTime = Number(response.data.data.otp_expire_duration_in_seconds);
          });

        }).catch((er) => {
          this.componentLoader = false;
          console.log(er);
        });
    },

    showLoginComp() {
      this.$emit('clicked', true)
    },


  },

  mounted() {
    console.log(this.titleText)
    this.$refs.nameElm.focus();
  },
};
