import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { Ripple } from 'vuetify/lib/directives'
import DatetimePicker from 'vuetify-datetime-picker'


Vue.use(Vuetify, {
 iconfont: 'fa' ,
 directives : {
    Ripple
 }
},
DatetimePicker
);

export default new Vuetify({
   
});
