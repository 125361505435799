import LogIn from "../../components/user/LogIn/LogIn.vue";

export default {
    name: 'Home',
    data() {
        return {
            loggedInUserToken:"",
        }
    },

    components: {
        LogIn
    },

    methods: {

    },

    computed: {
        loggedIn(){
            return this.$store.getters.loggedIn;
        }
    },

    mounted() {
        this.loggedInUserToken = this.$store.state.token;
    },
}