import Vue from 'vue'
import App from './App.vue'
import { store } from './store/store'
import router from './router'
import axios from 'axios'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import i18n from './i18n'
import vuetify from './plugins/vuetify';

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";


Vue.config.productionTip = false

axios.interceptors.response.use((response) => {
  if (response && response.data && response.data.message) {
    store.commit('getSuccessMessage', response.data.message)
  }

  return response;
}, (error) => {

  if (error && error.response && error.response.status !== 401) {

    if (error && error.response && error.response.status == 422) {

      store.commit('getApiErrorMessage', error.response.data.data)

    }else if(error && error.response && error.response.status == 500){
      store.commit('getApiErrorMessage', "Our server is down, Please contact admin")
    } else {
      if (error && error.response && error.response.data && error.response.data.message) {
        store.commit('getApiErrorMessage', error.response.data.message)
      }
      else {
        store.commit('getApiErrorMessage', "Something went wrong")
      }

    }

    return new Promise((resolve, reject) => {
      reject(error);
    });

  }

  if ((error && error.response && error.response.status === 401) && (error && error.response && error.response.data &&  error.response.data.message !== "Unauthorized")) {

    if (error && error.response && error.response.data && error.response.data.message) {
      store.commit('getApiErrorMessage', error.response.data.message)
    }

    return new Promise((resolve, reject) => {
      reject(error);
    });
  }else if ((error && error.response && error.response.status === 401) && (error && error.response && error.response.data &&  error.response.data.message === "Unauthorized")){
      store.dispatch('destroyToken').then(() => {
      router.push('/Home') // navigate to login or ask for login
    })
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }


  // Refresh token
  if (error && error.config && error.config.url === store.state.apiBaseUrl + '/refresh') {
    store.dispatch('destroyToken').then(() => {
      router.push('/Home')
    })
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  return store.dispatch('getNewToken')
    .then((token) => {

      const config = error.config;
      config.headers['Authorization'] = `Bearer ${token}`;
      return new Promise((resolve, reject) => {
        axios.request(config).then(response => {
          resolve(response);
        }).catch((error) => {
          reject(error);
        })
      });

    })
    .catch((error) => {
      Promise.reject(error);
    });
});

new Vue({
  i18n,
  render: h => h(App),
  store,
  vuetify,
  router
}).$mount('#app')
