import CountDown from "../../countdown/Index.vue";

export default {
  name: "CreatePassword",
  props: {
    createPasswordDetails : {
      type: Object
    }
  },
  data() {
    return {
      valid: true,
      lazy: false,
      password:"",
      validPassword:"",
      passwordStatusMessage:"",
      componentLoader:false,
      
      formRules : {
        required: value => !!value || this.$t('common.required'),
        password: value => !!value && value.length >=6 || this.$t('common.atLeastSixCharacters'),
        validPassword: value => !!value && value.length >= 6 && value == this.password || this.$t('common.shouldBeSame'),
      },
      successDialog:false,
      otp:null,
      otpTimeOutTime:null
    };
  },

  components: {
    CountDown
  },

  watch: {
    '$store.state.language' () {
      this.$refs.createPasswordForm.validate()
    },
  },

  methods: {

    
    createPassword() {
      if(this.$refs.createPasswordForm.validate()){ 
       
        this.componentLoader = true;
        this.$store.dispatch("connectToApi", {
          url: "user/change-password",
          params: {
            email:this.createPasswordDetails.email,
            otp: this.otp,
            password: this.password,
            password_confirmation: this.validPassword
          },
          rule: "patch",
          token: false,
          commonLoader: false,
        })
        .then((response) => {
          this.componentLoader = false;
          if (response.data.status_code == 200) {
            this.successDialog = true;
          } else {
            this.$store.commit('getApiErrorMessage',response.data.message);
          }
        }).catch((er) => {
          this.componentLoader = false;
          console.log(er);
        });


        
      }
    },



    showLogin(){
      this.successDialog = false;
      this.$emit('createPasswordSuccess')
    }
  },
  

  mounted() {

    console.log(this.createPasswordDetails)
    
    setTimeout(()=>{
      this.$refs.otp.focus();
      this.otpTimeOutTime = this.createPasswordDetails.optTimeout
    },500)
     
  },
};
