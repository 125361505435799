import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex, axios);

export const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        apiBaseUrl: process.env.VUE_APP_BASE_URL,
        token: localStorage.getItem('access_token') || null,
        language: localStorage.getItem('lang') || 'en',
        apiLoader: false,
        apiErrorMessage: '',
        userDetails: {},
        apiSuccessMessage: '',
        dataTableRowsPerPage: 15,
    },
    getters: {
        loggedIn(state) {
            return state.token != null;
        }
    },
    mutations: {
        storeToken(state, token) {
            state.token = token
        },

        storeUserDetails(state, user) {
            state.userDetails = user;
        },

        storeLogo(state, logo) {
            state.logo = logo;
        },

        removeToken(state) {
            state.userDetails = {}
            state.token = null;
        },

        showApiLoader(state) {
            state.apiLoader = true;
        },

        hideApiLoader(state) {
            state.apiLoader = false;
        },

        getApiErrorMessage(state, msg) {
            state.apiErrorMessage = msg;
        },

        getSuccessMessage(state, msg) {
            state.apiSuccessMessage = msg;
        },

        setLanguage(state, lang) {
            state.language = lang
        },

       


    },
    actions: {

        hideAPiLoader(context) {
            context.commit('hideApiLoader');
        },

        connectToApi(context, parameters) {

            if (parameters.commonLoader) {
                context.commit('showApiLoader');
            }
            let headers;
            
            if (parameters.token) {
                let assignToken = "Bearer " + context.state.token;
                headers = {
                    'Content-Type': 'application/json',
                    'Authorization': assignToken,
                    'X-Localization': context.state.language
                }
            } else {
                headers = {
                    'Content-Type': 'application/json',
                    'X-Localization': context.state.language
                }
            }


            return new Promise((resolve, reject) => {
                var url = parameters.url;
                var params = parameters.params;
                var rule = parameters.rule;
                console.log(context.state.apiBaseUrl);
                
                axios({
                    method: rule,
                    url: context.state.apiBaseUrl + url,
                    data: params,
                    headers: headers
                }).then(response => {
                    resolve(response);
                    context.commit('hideApiLoader');
                })
                    .catch(error => {
                        if (axios.isCancel(error)) return
                        context.commit('hideApiLoader');
                        reject(error);
                    });
            });
        },

        
        getNewToken(context) {

            let assignToken = "Bearer " + context.state.token;
            let headers = {
                'Content-Type': 'application/json',
                'Authorization': assignToken,
                'X-Localization': context.state.language
            }

            return new Promise((resolve, reject) => {
                axios({
                    method: 'post',
                    url: context.state.apiBaseUrl + "/refresh",
                    headers: headers
                })
                    .then(response => {
                        console.log('success - refresh token')


                        context.retrieveToken(response.data.token)
                        resolve(response.data.token);
                    })
                    .catch((error) => {
                        console.log('error - refresh token')
                        console.log(error)
                        reject(error);
                    });
            });
        },



        destroyToken(context) {
            if (context.getters.loggedIn) {
                return new Promise((resolve) => {
                    localStorage.removeItem('access_token');
                    context.commit('removeToken');
                    resolve(resolve)
                });
            }
        },

        retrieveToken(context, token) {
            return new Promise((resolve, reject) => {
                localStorage.setItem('access_token', token.token);
                context.commit('storeToken', token.token);
                if (token) {
                    resolve(resolve);
                }
                else {
                    reject("oops")
                }
            })
        },
    }
})